import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { MantineProvider } from '@mantine/core'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
   <React.StrictMode>
      <MantineProvider withGlobalStyles withNormalizeCSS>
         <App />
      </MantineProvider>
   </React.StrictMode>,
)

serviceWorkerRegistration.register()

reportWebVitals()
