import { RouterConfig } from './RouterConfig'

const App = () => {
   return (
      <>
         <RouterConfig />
      </>
   )
}

export default App
